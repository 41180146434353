/**
 * Safe ssr friendly id generator, since we're using libraries which are not connected to nuxt they don't have acces to the useId function,
 * so we need to create a fallback function for it.
 */
export const useSafeId = () => {
  if (typeof useId !== "undefined") {
    return useId();
  }
  return "";
};
